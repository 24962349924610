<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style>
  body {
    @apply overflow-hidden h-screen;
  }
</style>